import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { isOnLocalhost } from "./env";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyCrem1joEUh_cu5E1Qj4SooiZGtApGm_4o",
  authDomain: "questly-v3.firebaseapp.com",
  projectId: "questly-v3",
  storageBucket: "questly-v3.appspot.com",
  messagingSenderId: "378878177857",
  appId: "1:378878177857:web:fad8c4f571ea7ff38f0cc0",
  measurementId: "G-GPVBJES9RY",
});

export const firebaseAuth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

export const analytics = getAnalytics(firebaseApp);

firebaseAuth.useDeviceLanguage();

if (isOnLocalhost()) {
  connectAuthEmulator(firebaseAuth, "http://localhost:9100");
  connectFirestoreEmulator(firestore, "localhost", 8082);
  connectStorageEmulator(firebaseStorage, "localhost", 9201);
}
