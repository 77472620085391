export const useNavigationHelper = () => {
  const toQuesterAuth = () => {
    if (window.location.hostname === "localhost") {
      window.location.href = "http://localhost:3003/auth/quester";
    } else if (window.location.hostname === "questly.ngrok.app") {
      window.location.href = "https://questly-app.ngrok.app/auth/quester";
    } else {
      window.location.href = "https://app.questly.shopping/auth/quester";
    }
  };

  const toBusinessAuth = () => {
    if (window.location.hostname === "localhost") {
      window.location.href = "http://localhost:3003/auth/business";
    } else if (window.location.hostname === "questly.ngrok.app") {
      window.location.href = "https://questly-app.ngrok.app/auth/business";
    } else {
      window.location.href = "https://app.questly.shopping/auth/business";
    }
  };

  let landingUrl = "https://app.questly.shopping/auth/quester";
  if (window.location.hostname === "localhost") {
    landingUrl = "http://localhost:3002/";
  } else if (window.location.hostname === "questly.ngrok.app") {
    landingUrl = "https://questly.ngrok.app/";
  }

  const toLanding = () => {
    window.location.href = landingUrl;
  };

  return { toBusinessAuth, toQuesterAuth, landingUrl, toLanding };
};
