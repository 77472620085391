import { RouterOutput, trpc } from "../trpc";
import { firebaseAuth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { atom, useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { TRPCClientError } from "@trpc/react-query";
import { message } from "antd";
import { useCallback } from "react";

const setupInProgressAtom = atom(false);
const userDataAtom = atom<RouterOutput["user"]["currentUserData"] | undefined>(undefined);
let userDataLoading = false;

export const useUserData = () => {
  const [user] = useAuthState(firebaseAuth);
  // user is logged in, but we don't have the user data in backend yet - registered with phone number, user is in intermediate state
  const [setupInProgress, setSetupInProgress] = useAtom(setupInProgressAtom);
  const [userData, setUserData] = useAtom(userDataAtom);
  const navigate = useNavigate();

  const { mutateAsync } = trpc.user.currentUserData.useMutation();

  const fetchUserData = useCallback(
    async (firebaseUser = user) => {
      if (!firebaseUser) {
        setUserData(undefined);
        return null;
      }

      if (userDataLoading) {
        throw new Error("alreadyFetching");
      }

      try {
        userDataLoading = true;
        const resp = await mutateAsync();
        setUserData(resp);

        return resp;
      } catch (e) {
        console.error(e);
        if (e instanceof TRPCClientError) {
          message.error(e.message);
        }

        setUserData(undefined);
      } finally {
        userDataLoading = false;
      }
    },
    [user, setUserData, mutateAsync]
  );

  const logout = async () => {
    await firebaseAuth.signOut();
    setUserData(undefined);
    navigate("/");
  };

  return {
    isUserLoggedIn: !!user,
    userData: user ? userData : undefined,
    fetchUserData,
    userDataLoading,
    setupInProgress,
    setSetupInProgress,
    logout,
  };
};
