import { StyledButton } from "@components/StyledButton";
import { useTranslate } from "@tolgee/react";
import React from "react";

export const MyErrorBoundary: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslate("errorBoundary");

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-6 grow">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          {t("somethingWentWrong", "Something went wrong!")}
        </h1>
        <pre className="text-red-500 bg-red-100 p-4 rounded-lg whitespace-pre-wrap mb-6">
          {error.message}
        </pre>

        <StyledButton type="danger" onClick={resetErrorBoundary}>
          🔄 {t("tryAgain", "Try Again")}
        </StyledButton>
      </div>
    </div>
  );
};
