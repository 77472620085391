export const isDebug = () => window.location.hostname !== "app.questly.shopping";
export const supportedLanguages = () =>
  import.meta.env.VITE_LANGUAGES.split(",");
export const platformFeeRate = +import.meta.env.VITE_PLATFORM_FEE_RATE;
export const storeCurrency = import.meta.env.VITE_STORE_CURRENCY;

export const isOnLocalhost = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "questly.ngrok.app";

export const backendLink = isOnLocalhost()
  ? "http://localhost:3001/trpc"
  : "https://cloud.questly.shopping/trpc";
