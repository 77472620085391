import "./index.css";
import "scrollyfills";
import "./scrollTimeline.js";
import "leaflet/dist/leaflet.css";
import { Buffer } from "buffer";

window.Buffer = window.Buffer || Buffer;

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import { ErrorBoundary } from "react-error-boundary";
import { TolgeeProvider } from "@tolgee/react";
import { LoadingPage } from "@components/LoadingPage";
import { ConfigProvider } from "antd";
import { fallbackRender, tolgee } from "./setup";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4455bb",
        },
      }}
    >
      <TolgeeProvider tolgee={tolgee} fallback={<LoadingPage />}>
        <ErrorBoundary fallbackRender={fallbackRender}>
          <App />
        </ErrorBoundary>
      </TolgeeProvider>
    </ConfigProvider>
  </StrictMode>
);
